import { clone } from '~/helpers/javascript';
import { gtmFireEvent } from '~/helpers/gtm';
import { validateFulfilled } from './helper';
import { uploadMedias } from './generation.services';
import * as Sentry from '@sentry/nextjs';

export const stability = (prompts, stabilityPrompts, stylePreset) =>
  new Promise((resolve, reject) => {
    const validateResponse = (response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    };

    let body = {
      height: 640,
      width: 448
    };

    let textPrompts = [];
    if (stabilityPrompts.length !== 0) {
      const _prompts = stabilityPrompts[0].text;
      textPrompts = clone(stabilityPrompts);
      textPrompts[0].text = prompts + _prompts;

      textPrompts.push({
        text: 'text, watermark, signature, missing legs, extra arms, extra legs, fused fingers, too many fingers',
        weight: -1
      });

      body.text_prompts = textPrompts;
      if (stylePreset !== 'none') body.style_preset = stylePreset;
    } else {
      textPrompts = [
        {
          text: prompts,
          weight: 1
        }
      ];
      body.text_prompts = textPrompts;
      body.style_preset = stylePreset;
    }

    gtmFireEvent({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'request-stability-api'
    });

    const url =
      'https://api.stability.ai/v1/generation/stable-diffusion-xl-beta-v2-2-2/text-to-image';
    const apiKey = 'sk-QWAaigdShEENb7CqbgPfSI8qIfILNox51maDda08M6p0q2xY';

    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 120000);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'image/png',
        Authorization: apiKey
      },
      body: JSON.stringify({ ...body }),
      signal: controller.signal
    })
      .then(validateResponse)
      .then((response) => {
        // throw { message: 'Manual Throw Error' };
        return response.blob();
      })
      .then((r) => {
        clearTimeout(timeout);

        resolve(r);
      })
      .catch((err) => {
        console.log('ERROR - api.stability - ', err);
        gtmFireEvent({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-request-stability-api',
          params: {
            error: `${err}`,
            prompts: `${JSON.stringify(textPrompts)}`
          }
        });
        Sentry.captureException(err);
        fetch('https://api-dev.woxo.tech/gpt-completion/logging', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: {
              action: 'error-request-stability-api',
              error: err,
              prompts: `${JSON.stringify(textPrompts)}`
            }
          })
        })
          .then()
          .catch((e) => console.log(e));

        reject(err);
      });
  });

export const dreaming = (videos, stabilityPrompts, stylePreset) =>
  new Promise((resolve) => {
    Promise.allSettled(
      videos.map((video) => {
        let medias = [];
        video.scenes?.forEach((s) => {
          if (s['image-prompt']) {
            medias.push(stability(s['image-prompt'], stabilityPrompts, stylePreset));
          }
        });

        if (medias.length === 0) {
          medias = [
            stability(video.meta.mediaDescription, stabilityPrompts, stylePreset),
            stability(video.meta.mediaDescription, stabilityPrompts, stylePreset)
          ];
        }

        return Promise.allSettled(medias);
      })
    ).then(async (data) => {
      let _data = [];
      for (let i = 0; i < data.length; i++) {
        const d = data[i];
        const fulfilled = validateFulfilled(d.value);
        if (fulfilled) {
          let _medias = [];
          d.value.forEach((m) => {
            _medias.push(m.value);
          });

          const medias = [{ medias: _medias }];
          const _uploadMedias = await uploadMedias(medias);
          // console.log('_uploadMedias', _uploadMedias, medias);
          if (_uploadMedias[0].status === 'fulfilled') {
            _data[i] = {
              status: 'fulfilled',
              value: _uploadMedias[0].medias
            };
          } else {
            _data[i] = {
              status: 'rejected',
              value: []
            };
          }
        } else {
          _data[i] = {
            status: 'rejected',
            value: []
          };
        }
      }
      // console.log('DATA_', data, _data);
      resolve(_data);
    });
  });
